<template>
    <div>
        <loading-animation class="card bs-4" v-if="DataLoading"></loading-animation>
        <!--    Attendance Details    -->
        <div v-else>
            <div class="fl-te-c m-2 mb-4">
                <h4 class="text-capitalize">{{ name }} <span class="text-muted small text-capitalize"> {{ role }}</span>
                </h4>
                <div class="btn-group fl-eq">
                    <!--                <div class="btn-group">-->
                    <btn size="xs" v-if="details" text="Download" @click="AttendanceDownload" :loading="loading"
                         loading-text="downloading..."></btn>
                    <drop-down-button :text="year.toString()" size="sm" enter-animation="zoomIn"
                                      exit-animation="zoomOut"
                                      icon="fa fa-chevron-down">
                        <drop-down-item v-for="year in yearOptions" v-bind:key="year.value"
                                        @click="setYear(year.value)" :text="year.value"/>
                    </drop-down-button>
                    <drop-down-button :text="monthLabel" size="sm" enter-animation="zoomIn" exit-animation="zoomOut"
                                      icon="fa fa-chevron-down">
                        <drop-down-item v-for="month in monthOptions" v-bind:key="month.value"
                                        @click="setMonth(month)" :text="month.label"/>
                    </drop-down-button>
                </div>
            </div>
            <div v-if="details" class="card bs-4 m-2">
                <div class="bb-2 p-2 pb-3 row">
                    <div v-if="details.full_day" class="col-2">Full Days: <b>{{ details.full_day }} Days</b></div>
                    <div v-if="!details.full_day" class="col-2">Full Days: <b>0 Days</b></div>
                    <div v-if="details.leave" class="col-2">Leave Days: <b>{{ details.leave }} Days</b></div>
                    <div v-if="!details.leave" class="col-2">Leave Days: <b>0 Days</b></div>
                    <div v-if="details.half_day" class="col-2">Half Day: <b>{{ details.half_day }} Days</b></div>
                    <div v-if="!details.half_day" class="col-2">Half Day: <b>0 Days</b></div>
                    <div v-if="details.one_hour" class="col-2">One Hour: <b>{{ details.one_hour }} Hrs</b></div>
                    <div v-if="!details.one_hour" class="col-2">One Hour: <b>0 Hrs</b></div>
                    <div v-if="details.one_hour" class="col-2">Two Hour: <b>{{ details.two_hour }} Hrs</b></div>
                    <div v-if="!details.one_hour" class="col-2">Two Hour: <b>0 Hrs</b></div>
                </div>
                <div class="row">
                    <div class="col-md-2 br-2" v-for="record in details.attendance" :key="record.day">
                        <div class="mt-3">
                            <span><strong>{{ record.day }}</strong></span>
                            <span>  -     {{ record.attendance }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!details" class="card bg-primary bs-4 text-center">No attendance available for the staff.</div>
        </div>
    </div>
</template>

<script>
import urls from '../../../data/urls';
import axios from 'secure-axios';
import { mapGetters } from 'vuex';

function generateArrayOfYears () {
    const date = new Date().getFullYear();
    const min = date - 10;
    const years = [];

    for (let i = min; i <= date; i++) {
        const data = { label : i, value : i };
        years.push(data);
    }
    return years.reverse();
}

export default {
    name : 'AttendanceReportPage',
    data () {
        const Options = generateArrayOfYears();
        return {
            yearOptions  : Options,
            loading      : false,
            name         : '',
            role         : '',
            year         : new Date().getFullYear(),
            month        : new Date().getMonth() + 1,
            monthLabel   : '',
            user         : this.$route.params.id,
            details      : '',
            DataLoading  : false,
            monthOptions : [
                { label : 'January', value : 1 },
                { label : 'February', value : 2 },
                { label : 'March', value : 3 },
                { label : 'April', value : 4 },
                { label : 'May', value : 5 },
                { label : 'June', value : 6 },
                { label : 'July', value : 7 },
                { label : 'August', value : 8 },
                { label : 'September', value : 9 },
                { label : 'October', value : 10 },
                { label : 'November', value : 11 },
                { label : 'December', value : 12 }
            ]
        };
    },
    mounted () {
        this.setDate();
        this.loadDetails();
    },
    watch : {
        year : {
            handler () {
                this.loadDetails();
            }
        },
        month : {
            handler () {
                this.loadDetails();
            }
        }
    },
    computed : {
        ...mapGetters(['currentUser'])
    },
    methods : {
        setDate () {
            const monthID = new Date().getMonth();
            this.monthLabel = this.monthOptions[monthID].label;
        },
        setYear (year) {
            this.year = year;
        },
        setMonth (month) {
            this.month = month.value;
            this.monthLabel = month.label;
        },
        async loadDetails () {
            const that = this;
            that.DataLoading = true;
            that.DataLoading = false;
            const response = await axios.form(urls.hrAdmin.reports.attendanceReportDetails, {
                year  : that.year,
                user  : that.user,
                month : that.month
            });
            const json = response.data;
            if (json.error === false) {
                that.details = json.data;
                that.name = json.data.user;
                that.role = json.data.role;
                that.DataLoading = false;
            } else if (json.msg.__all__ === 'No Attendance exists for this user') {
                that.details = '';
                that.DataLoading = false;
                that.$notify('No attendance available', 'Message', {
                    type : 'info'
                });
            } else {
                that.details = '';
                that.DataLoading = false;
                that.$notify('No details available', 'Message', {
                    type : 'info'
                });
            }
        },
        async AttendanceDownload () {
            this.loading = true;
            const component = this;
            const response = await axios.form(urls.hrAdmin.reports.attendanceDownload, {
                year  : component.year,
                user  : component.user,
                month : component.month
            });
            const json = response.data;
            if (json.error === false) {
                window.open(json.url);
                component.loading = false;
            } else {
                component.loading = false;
                component.$notify('No details available', 'Message', {
                    type : 'info'
                });
            }
        }
    }
}
;
</script>
